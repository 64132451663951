const navLinks = {
  website: "https://www.cryptolegions.app/",
  buyBLSTLink:
    "https://pancakeswap.finance/swap?outputCurrency=0x63441E5C9F55B5A9141f3D834a28426Ca1c5C5cC&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  whitelist: "https://cryptolegions.app/apply-whitelist",
  whitePaper: "https://docs.cryptolegions.app/",
  play: "https://play.cryptolegions.app/",
  presale:
    "https://www.pinksale.finance/launchpad/0x4bB0A2Df3b0F25545bA6301FD44b801A094918b8?chain=BSC",
  discord: "https://www.cryptolegions.app/d",
  telegram: "https://www.cryptolegions.app/t",
  twitter: "https://www.cryptolegions.app/tw",
  youtube: "https://www.cryptolegions.app/y",
  medium: "https://www.cryptolegions.app/m",
  company: "https://www.cryptogames.agency",
  policy: "https://www.cryptolegions.app/privacy-policy/",
  termAndConditions: "https://www.cryptolegions.app/terms-and-conditions/",
  binance:
    "https://bscscan.com/token/0x63441E5C9F55B5A9141f3D834a28426Ca1c5C5cC",
  certik: "https://www.cryptolegions.app/certik",
  chainlink:
    "https://cryptolegions.medium.com/crypto-legions-integrates-chainlink-vrf-for-provably-random-gameplay-dbabc6940ce",
  poocoin:
    "https://poocoin.app/tokens/0x63441e5c9f55b5a9141f3d834a28426ca1c5c5cc",
  dextools:
    "https://www.dextools.io/app/bnb/pair-explorer/0x13fade99f5d7038cd53261770d80902c8756adae",
  coinmarketcap: "https://coinmarketcap.com/currencies/crypto-legions-v3/",
  coingecko: "https://www.coingecko.com/en/coins/crypto-legions-v3",
  red10flags:
    "https://www.cryptolegions.app/10-red-flags-checklist-for-play-to-earn-games/",
  ch: "https://www.cryptolegions.app/ch",
  flag: "https://www.cryptolegions.app/flags/",
  whitelistInfo: "https://www.cryptolegions.app/whitelist/",
  v2surveyResults: "https://www.cryptolegions.app/v2survey-results/",
  walletConfirmation: "https://www.cryptolegions.app/wallet-confirmation/",
  walletConfirmationThankYou:
    "https://www.cryptolegions.app/wallet-confirmation-thank-you/",
  whitelistPlayersThanks:
    "https://www.cryptolegions.app/whitelist-players-thank-you/",
  aaron: "https://www.cryptolegions.app/aaron/",
  whitelistPlayers: "https://www.cryptolegions.app/whitelist-players/",
  proof: "https://www.cryptolegions.app/proof/",
  whitelistApplicationClosed:
    "https://www.cryptolegions.app/whitelist-applications-closed/",
  whitelistBCGApplicationsClosed:
    "https://www.cryptolegions.app/whitelist-bcg-applications-closed/",
  AMA: "https://www.cryptolegions.app/ama/",
  whitelistV3: "https://www.cryptolegions.app/whitelistv3",
  nftContest: "https://www.cryptolegions.app/nft-contest",
  v2Survey: "https://www.cryptolegions.app/v2survey",
  f: "https://www.cryptolegions.app/f",
  referralsScript: "https://www.cryptolegions.app/referrals-script",
  applyModerator: "https://www.cryptolegions.app/apply-moderator",
  moderatorTest: "https://www.cryptolegions.app/moderator-test",
  whitelistCorrection: "https://www.cryptolegions.app/whitelist-correction",
  compensationStatus: "https://www.cryptolegions.app/compensation-status",
  players: "https://www.cryptolegions.app/players",
  traders: "https://www.cryptolegions.app/traders",
  pancake: "https://www.cryptolegions.app/pancake",
  dextoolLink: "https://www.cryptolegions.app/dextools",
  marketing: "https://www.cryptolegions.app/marketing",
  influencerResources: "https://www.cryptolegions.app/influencer-resources",
  applyReview: "https://www.cryptolegions.app/apply-review",
  applyWhitelist: "https://www.cryptolegions.app/apply-whitelist",
  ta: "https://www.cryptolegions.app/ta",
  applyInfluencer: "https://www.cryptolegions.app/apply-influencer",
};

export default navLinks;
