import React from "react";
// import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import MainHome from "./pages/main/Home";
import LanguageContext from "./LanguageContext";
import navLinks from "./config/constants";

function App() {
  const location = window.location;
  console.log("location: ", location);
  const pathname = location.pathname;
  switch (pathname) {
    case "/d":
      window.location.href = navLinks.discord;
      break;
    case "/t":
      window.location.href = navLinks.telegram;
      break;
    case "/tw":
      window.location.href = navLinks.twitter;
      break;
    case "/y":
      window.location.href = navLinks.youtube;
      break;
    case "/m":
      window.location.href = navLinks.medium;
      break;
    case "/privacy-policy":
      window.location.href = navLinks.policy;
      break;
    case "/terms-and-conditions":
      window.location.href = navLinks.termAndConditions;
      break;
    case "/10-red-flags-checklist-for-play-to-earn-games":
      window.location.href = navLinks.red10flags;
      break;
    case "/ch":
      window.location.href = navLinks.ch;
      break;
    case "/flags":
      window.location.href = navLinks.flag;
      break;
    case "/whitelist":
      window.location.href = navLinks.whitelistInfo;
      break;
    case "/v2survey-results":
      window.location.href = navLinks.v2surveyResults;
      break;
    case "/wallet-confirmation":
      window.location.href = navLinks.walletConfirmation;
      break;
    case "/wallet-confirmation-thank-you":
      window.location.href = navLinks.walletConfirmationThankYou;
      break;
    case "/whitelist-players-thank-you":
      window.location.href = navLinks.whitelistPlayersThanks;
      break;
    case "/aaron":
      window.location.href = navLinks.aaron;
      break;
    case "/whitelist-players":
      window.location.href = navLinks.whitelistPlayers;
      break;
    case "/proof":
      window.location.href = navLinks.proof;
      break;
    case "/whitelist-applications-closed":
      window.location.href = navLinks.whitelistApplicationClosed;
      break;
    case "/whitelist-bcg-applications-closed":
      window.location.href = navLinks.whitelistBCGApplicationsClosed;
      break;
    case "/ama":
      window.location.href = navLinks.AMA;
      break;
    case "/whitelistv3":
      window.location.href = navLinks.whitelistV3;
      break;
    case "/nft-contest":
      window.location.href = navLinks.nftContest;
      break;
    case "/v2survey":
      window.location.href = navLinks.v2Survey;
      break;
    case "/f":
      window.location.href = navLinks.f;
      break;
    case "/referrals-script":
      window.location.href = navLinks.referralsScript;
      break;
    case "/apply-moderator":
      window.location.href = navLinks.applyModerator;
      break;
    case "/moderator-test":
      window.location.href = navLinks.moderatorTest;
      break;
    case "/whitelist-correction":
      window.location.href = navLinks.whitelistCorrection;
      break;
    case "/compensation-status":
      window.location.href = navLinks.compensationStatus;
      break;
    case "/players":
      window.location.href = navLinks.players;
      break;
    case "/traders":
      window.location.href = navLinks.traders;
      break;
    case "/pancake":
      window.location.href = navLinks.pancake;
      break;
    case "/dextools":
      window.location.href = navLinks.dextoolLink;
      break;
    case "/marketing":
      window.location.href = navLinks.marketing;
      break;
    case "/influencer-resources":
      window.location.href = navLinks.influencerResources;
      break;
    case "/apply-review":
      window.location.href = navLinks.applyReview;
      break;
    case "/apply-whitelist":
      window.location.href = navLinks.applyWhitelist;
      break;
    case "/ta":
      window.location.href = navLinks.ta;
      break;
    case "/apply-influencer":
      window.location.href = navLinks.applyInfluencer;
      break;
    case "/certik":
      window.location.href = navLinks.certik;
      break;

    case "/d/":
      window.location.href = navLinks.discord;
      break;
    case "/t/":
      window.location.href = navLinks.telegram;
      break;
    case "/tw/":
      window.location.href = navLinks.twitter;
      break;
    case "/y/":
      window.location.href = navLinks.youtube;
      break;
    case "/m/":
      window.location.href = navLinks.medium;
      break;
    case "/privacy-policy/":
      window.location.href = navLinks.policy;
      break;
    case "/terms-and-conditions/":
      window.location.href = navLinks.termAndConditions;
      break;
    case "/10-red-flags-checklist-for-play-to-earn-games/":
      window.location.href = navLinks.red10flags;
      break;
    case "/ch/":
      window.location.href = navLinks.ch;
      break;
    case "/flags/":
      window.location.href = navLinks.flag;
      break;
    case "/whitelist/":
      window.location.href = navLinks.whitelistInfo;
      break;
    case "/v2survey-results/":
      window.location.href = navLinks.v2surveyResults;
      break;
    case "/wallet-confirmation/":
      window.location.href = navLinks.walletConfirmation;
      break;
    case "/wallet-confirmation-thank-you/":
      window.location.href = navLinks.walletConfirmationThankYou;
      break;
    case "/whitelist-players-thank-you/":
      window.location.href = navLinks.whitelistPlayersThanks;
      break;
    case "/aaron/":
      window.location.href = navLinks.aaron;
      break;
    case "/whitelist-players/":
      window.location.href = navLinks.whitelistPlayers;
      break;
    case "/proof/":
      window.location.href = navLinks.proof;
      break;
    case "/whitelist-applications-closed/":
      window.location.href = navLinks.whitelistApplicationClosed;
      break;
    case "/whitelist-bcg-applications-closed/":
      window.location.href = navLinks.whitelistBCGApplicationsClosed;
      break;
    case "/ama/":
      window.location.href = navLinks.AMA;
      break;
    case "/whitelistv3/":
      window.location.href = navLinks.whitelistV3;
      break;
    case "/nft-contest/":
      window.location.href = navLinks.nftContest;
      break;
    case "/v2survey/":
      window.location.href = navLinks.v2Survey;
      break;
    case "/f/":
      window.location.href = navLinks.f;
      break;
    case "/referrals-script/":
      window.location.href = navLinks.referralsScript;
      break;
    case "/apply-moderator/":
      window.location.href = navLinks.applyModerator;
      break;
    case "/moderator-test/":
      window.location.href = navLinks.moderatorTest;
      break;
    case "/whitelist-correction/":
      window.location.href = navLinks.whitelistCorrection;
      break;
    case "/compensation-status/":
      window.location.href = navLinks.compensationStatus;
      break;
    case "/players/":
      window.location.href = navLinks.players;
      break;
    case "/traders/":
      window.location.href = navLinks.traders;
      break;
    case "/pancake/":
      window.location.href = navLinks.pancake;
      break;
    case "/dextools/":
      window.location.href = navLinks.dextoolLink;
      break;
    case "/marketing/":
      window.location.href = navLinks.marketing;
      break;
    case "/influencer-resources/":
      window.location.href = navLinks.influencerResources;
      break;
    case "/apply-review/":
      window.location.href = navLinks.applyReview;
      break;
    case "/apply-whitelist/":
      window.location.href = navLinks.applyWhitelist;
      break;
    case "/ta/":
      window.location.href = navLinks.ta;
      break;
    case "/apply-influencer/":
      window.location.href = navLinks.applyInfluencer;
      break;
    case "/certik/":
      window.location.href = navLinks.certik;
      break;
    default:
      break;
  }
  return (
    <LanguageContext>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<MainHome />} />
        </Routes>
      </BrowserRouter>
    </LanguageContext>
  );
}

export default App;
