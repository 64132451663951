import React, { useContext } from "react";
import language from "../config/language";
import navLinks from "../config/constants";
import { LanguageContext } from "../LanguageContext";

export default function ActionBtns(props) {
  const { setTrailerOpen } = props;
  const [localLang] = useContext(LanguageContext);

  return (
    <div className="flex flex-wrap justify-around action-btns">
      <a
        className="button text-glow btn-trailer"
        onClick={() => setTrailerOpen(true)}
      >
        {language.section1.btnTrailer[localLang].toUpperCase()}
      </a>

      <a
        className="button text-glow btn-buy"
        href={navLinks.buyBLSTLink}
        target={"_blank"}
        rel="noreferrer"
      >
        {language.section1.btnBuyBLST[localLang].toUpperCase()}
      </a>
      <a
        className="button text-glow btn-play-now"
        href={navLinks.play}
        target={"_blank"}
        rel="noreferrer"
      >
        {language.section1.btnPlay[localLang].toUpperCase()}
      </a>
    </div>
  );
}
