import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { useCallback } from 'react';
import { loadFirePreset } from 'tsparticles-preset-fire';

const ParticleBG = () => {
  // this customizes the component tsParticles installation
  const customInit = async (engine) => {
    // this adds the preset to tsParticles, you can safely use the
    await loadFirePreset(engine);
  };

  let option = {
    fullScreen: false,
    interactivity: {
      events: {
        onHover: {
          enable: false,
          mode: 'repulse',
        },
        resize: true,
      },
    },
    particles: {
      color: {
        value: ['#fdcf58', '#eb5b34', '#f27d0c', '#800909', '#f07f13'],
      },
      move: {
        direction: 'right',
        enable: true,
        random: true,
        speed: 15,
        straight: false,
        attract: {
          enable: false,
          rotateX: 600,
          rotateY: 1200,
        },
      },
      number: {
        value: 300,
      },
      opacity: {
        value: 0.8,
        random: true,
        anim: {
          enable: true,
          speed: 3,
          opacity_min: 0.1,
          sync: false,
        },
      },
      twinkle: {
        particles: {
          enable: true,
          color: '#fff',
          frequency: 0.05,
          opacity: 1,
        },
        lines: {
          enable: true,
          color: '#fff',
          frequency: 0.005,
          opacity: 1,
        },
      },
      shape: {
        type: 'circle',
      },
      size: {
        value: { min: 0, max: 2 },
      },
    },
    detectRetina: true,
  };
  return (
    <Particles
      id="tsparticles"
      init={customInit}
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      options={option}
    />
  );
};

export default ParticleBG;
